<template>
    <div class="search_filter_wrap">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                >
                    <el-option :value="'ina'" :label="$t('subject')"></el-option>
                    <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                    <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
                    <el-option :value="'cuni'" :label="$t('curator')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"/>
                <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                    <span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <el-popover
                        popper-class="top_500"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg popup_bg_radius">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{ $t('category') }}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterCategory"
                                                       @change="setFilterFunc('ic', filterCategory)">
                                        <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                                        <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                                        <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                                        <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                                        <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                                        <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('edition') }}</div>
                                <div class="edition_wrap">
                                    <el-checkbox-group v-model="filterEdition"
                                                       @change="setFilterFunc('ed', filterEdition)">
                                        <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                                        <el-checkbox :label="0"> {{ $t('single') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('type') }}</div>
                                <div class="type_wrap">
                                    <el-checkbox-group v-model="filterType"
                                                       @change="setFilterFunc('ty', filterType)">
                                        <el-checkbox :label="'digital'">{{ $t('digital') }}</el-checkbox>
                                        <el-checkbox :label="'real'">{{ $t('real') }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('status') }}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group v-model="filterStatus"
                                                       @change="  setFilterFunc('imst', filterStatus)">
                                        <el-checkbox :label="'auction'">
                                            {{ $t('auction') }}
                                        </el-checkbox>
                                        <el-checkbox :label="'license'">
                                            {{ $t('license_sell') }}
                                        </el-checkbox>
                                        <el-checkbox :label="'fix'">
                                            {{ $t('fix_selling') }}
                                        </el-checkbox>
                                        <el-checkbox :label="'no'">
                                            {{ $t('no_sell') }}
                                        </el-checkbox>
                                        <el-checkbox :label="'competition'">
                                            {{ $t('competition_apply') }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{ $t('c_request') }}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group v-model="filterCuration"
                                                       @change="setFilterFunc('iscu', filterCuration)">
                                        <el-checkbox :label="1"> {{ $t('apply1') }}</el-checkbox>
                                        <el-checkbox :label="2"> {{ $t('not_apply') }}</el-checkbox>

                                    </el-checkbox-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-popover>
            </div>


        </div>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";

const {filterConfig} = itemUtil
export default {
    name: "ItemFilterHoldingLayout",
    mixins: [itemUtil],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterCuration: [],
            filterEdition: [],
            filterType: [],
            statusArr: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
